//
// Main content
//


// .main-content {
//     .container-fluid {
//         padding-top: 76px;
//         padding-bottom: $main-content-padding-y;
//
//         @include media-breakpoint-up(md) {
//             padding-left: $main-content-padding-x !important;
//             padding-right: $main-content-padding-x !important;
//         }
//     }
// }
