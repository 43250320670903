@charset "utf-8";
// @import "minima";
@import "purpose";
@import "swiper";

.patrocinador img {
  width: 100%;
}
// .realizacao {}
.newton {
  padding-right: 2rem;
  padding-left: 2rem;
}
// .einstein {}
.galileu {
  padding-right: 2rem;
  padding-left: 2rem;
}
// .tesla {}
.apoio {
  padding-right: 2rem;
  padding-left: 2rem;
}

.parceiros {
  padding-right: 2rem;
  padding-left: 2rem;
}

.bg-gradient-seng {
  background: linear-gradient(50deg, $azul_seng 0, $vermelho_seng 100%) !important;
}

.filter-form .badge {
  line-height: 3;
  cursor: pointer;
}

p.text-muted.function {
  margin-top: -40px;
  margin-bottom: -20px;
}

.icons-media {
  top: 30%;
  left: 0;
  transform: translateY(-50%);
  position: fixed;
  z-index: 100;
}

.icons-media .icons-social {
  padding: initial;
}

.icons-media .icons-social .i-icon {
  height: 35px;
  width: 35px;
  list-style-type: none;
  padding-left: 10px;
  padding-top: 8px;
  margin-top: 5px;
  color: #fff;
  background: #000;
}

.icons-media .icons-social .i-icon:hover {
  padding-left: 30px;
  width: 80px;
  transition: 1s;
}
