// 
// Card columns
//


[data-lg-columns="2"] {
    column-count: 2 !important;
}

[data-lg-columns="3"] {
    @include media-breakpoint-only(lg) {
        column-count: 3 !important;
    }
}

[data-lg-columns="4"] {
    @include media-breakpoint-only(lg) {
        column-count: 4 !important;
    }
}
