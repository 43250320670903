//
// Functions
// overrides or extension after the Bootstrap's defaults
//


// Retrieve color Sass maps
@function section-color($key: "primary") {
	@return map-get($section-colors, $key);
}
