//
// Icon
//


.icon {
	// display: inline-flex;
	min-width: $icon-size;
	min-height: $icon-size;
	line-height: $icon-size;

	i, svg {
		font-size: $icon-size - .25;
	}

	img {
		width: $icon-size;
		height: $icon-size;
	}
}

.icon + .icon-text {
	padding-left: 1rem;
	width: calc(100% - #{$icon-size} - 1);
}


// Size variations

.icon-xl {
	min-width: $icon-size-xl;
	min-height: $icon-size-xl;
	line-height: $icon-size-xl;

	i, svg {
		font-size: $icon-size-xl - .25;
	}

	img {
		width: $icon-size-xl;
		height: $icon-size-xl;
	}
}

.icon-xl + .icon-text {
	width: calc(100% - #{$icon-size-xl} - 1);
}

.icon-lg {
	min-width: $icon-size-lg;
	min-height: $icon-size-lg;
	line-height: $icon-size-lg;

	i, svg {
		font-size: $icon-size-lg - .25rem;
	}

	img {
		width: $icon-size-lg;
		height: $icon-size-lg;
	}
}

.icon-lg + .icon-text {
	width: calc(100% - #{$icon-size-lg} - 1);
}

.icon-sm {
	min-width: $icon-size-sm;
	min-height: $icon-size-sm;
	line-height: $icon-size-sm;

	i, svg {
		font-size: $icon-size-sm - .25rem;
	}

	img {
		width: $icon-size-sm;
		height: $icon-size-sm;
	}
}

.icon-sm + .icon-text {
	width: calc(100% - #{$icon-size-sm} - 1);
}

.icon-xs {
	min-width: $icon-size-xs;
	min-height: $icon-size-xs;
	line-height: $icon-size-xs;

	i, svg {
		font-size: $icon-size-xs - .25rem;
	}

	img {
		width: $icon-size-xs;
		height: $icon-size-xs;
	}
}

.icon-xs + .icon-text {
	width: calc(100% - #{$icon-size-xs} - 1);
}
