//
// Button group
//

.btn-group-options {
    .btn {
        min-height: 4.8rem;
        display: flex;
        align-items: center;
    }
}
