//
// Card
//


.card {
    position: relative;
    margin-bottom: $grid-gutter-width;

    @if $enable-shadows {
        @include box-shadow($card-shadow);
    }

    &.hover-scale-110:hover {
        z-index: 2;
        @include border-radius($card-border-radius !important);
    }
}

.card-header {
     border-bottom: $card-cap-border-width solid $card-cap-border-color;
}

// Images as card backgrounds

.card-img-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    border-radius: $card-border-radius;
    -webkit-backface-visibility: hidden;
    @include transition($transition-base);

    & ~ .card-body {
        position: relative;
    }
}

.card {
    &:hover {
        .card-img-bg {
            transform: scale(1.1);
        }
    }
}

// IE fixes

.card {
    .card-image,
    .animate-this,
    > a {
        min-height: 1px;
    }
}
