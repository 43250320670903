//
// Timeline
//


.timeline {
    position: relative;

    // Axis

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 1rem;
        height: 100%;
        border-right: $timeline-axis-width solid $timeline-axis-color;
    }
}

// Axis style variations

[data-timeline-axis-style="dashed"] {
    &:before {
        border-right-style: dashed !important;
    }
}

[data-timeline-axis-style="dotted"] {
    &:before {
        border-right-style: dotted !important;
    }
}

// Timeline blocks

.timeline-block {
    position: relative;
    margin: 2em 0;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

// Timeline steps

.timeline-step {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background: $timeline-step-bg;
    border: $timeline-step-border-width solid $timeline-step-border-color;
    text-align: center;
    transform: translateX(-50%);
    font-size: 1rem;
    font-weight: $font-weight-bold;
    z-index: 1;

    svg, i {
        line-height: 1.4;
    }
}

.timeline-step-icon {
    background: $white;
    border: $timeline-step-border-width solid $timeline-step-border-color;
}

.timeline-step-xs {
    width: 17px;
    height: 17px;
    font-size: .75rem;
}

.timeline-step-sm {
    width: 23px;
    height: 23px;
    font-size: .75rem;
}

.timeline-step-lg {
    width: 47px;
    height: 47px;
    font-size: 1.75rem;
}

// Timeline content and body
.timeline-content {
    position: relative;
    margin-left: 60px;
    margin-right: 30px;
    position: relative;
    top: -6px;

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.timeline-body {
    padding: 1.5rem;
}

@include media-breakpoint-up(lg) {
    .timeline {
        &:before {
            left: 50%;
            margin-left: -2px;
        }
    }

    .timeline-step {
        left: 50%;
    }

    .timeline-content {

        width: 38%;
    }

    .timeline-body {
        padding: 1.5rem;
    }

    .timeline-block:nth-child(even) .timeline-content {
        float: right;
    }

    // Timeline axis colors

    @each $color, $value in $theme-colors {
        [data-timeline-axis-color="#{$color}"]  {
            &:before {
                border-color: $value;
            }
        }
    }
}

// One side timeline

.timeline-one-side {
    &:before {
        left: 1rem;
    }

    .timeline-step {
        left: 1rem;
    }

    .timeline-content {
        width: auto;
    }
}

.timeline-one-side .timeline-block:nth-child(even) .timeline-content {
    float: none;
}
